import { get, put, del, post } from './requests';
import queryString from 'query-string';

export function getTrainingLevels() {
  return get('/admin/v1/levels').then(res => res.json());
}

export function saveTrainingLevels(levels) {
  return put('/admin/v1/levels', { levels });
}

export function getWarmupConfigs() {
  return get('/admin/v1/warmups').then(res => res.json());
}

export function saveWarmupConfigs(warmups) {
  return put('/admin/v1/warmups', { warmups });
}

export function getDeltaConfigs() {
  return get('/admin/v1/deltas').then(res => res.json());
}

export function saveDeltaConfigs(deltas) {
  return put('/admin/v1/deltas', { deltas });
}

export function getTrainers() {
  return get('/admin/v1/coaches').then(res => res.json());
}

export function getTrainerDetail(id) {
  return get(`/admin/v1/coaches/${id}`).then(res => res.json());
}

export function updateTrainer(id, payload) {
  return put(`/admin/v1/coaches/${id}`, { coach: payload });
}

export function deleteTrainer(id) {
  return del(`/admin/v1/coaches/${id}`);
}

export function unassignFamily(trainerId, familyId) {
  return del(`/admin/v1/coaches/${trainerId}/families/${familyId}`);
}

export function assignFamily(trainerId, familyId) {
  return put(`/admin/v1/coaches/${trainerId}/families/${familyId}`);
}

export function search(q) {
  const url = queryString.stringifyUrl({
    url: '/admin/v1/search',
    query: { q }
  }, { skipNull: true });
  return get(url).then(res => res.json());
}

export function getPreviewList(random = false) {
  const url = queryString.stringifyUrl({
    url: '/admin/v1/ai/families',
    query: { random }
  }, { skipNull: true });
  return get(url).then(res => res.json());
}

export function getFamilyPreview(familyId) {
  return get(`/admin/v1/ai/families/${familyId}`).then(res => res.json());
}

export function getAISettings() {
  return get('/admin/v1/ai/settings').then(res => res.json());
}

export function saveAISettings(settings) {
  return post('/admin/v1/ai/settings', { settings });
}

export function getAIQuestions() {
  return get('/admin/v1/ai/questions').then(res => res.json());
}

export function updateAIQuestion(id, question) {
  return put(`/admin/v1/ai/questions/${id}`, { question });
}

export function createAIQuestion(question) {
  return post(`/admin/v1/ai/questions`, { question });
}

export function deleteAIQuestion(id) {
  return del(`/admin/v1/ai/questions/${id}`);
}

export function askAIQuestion(familyId, questionId) {
  const url = queryString.stringifyUrl({
    url: `/admin/v1/ai/families/${familyId}/ask`,
    query: { question_id: questionId }
  }, { skipNull: true });
  return get(url).then(res => res.json());
}

export function getAIRecents() {
  return get('/admin/v1/ai/recents').then(res => res.json());
}

export function getAIHistory(familyId) {
  return get(`/admin/v1/ai/history/${familyId}`).then(res => res.json());
}