import filter from 'lodash/fp/filter';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import mv from 'lodash/fp/mapValues';
import pickBy from 'lodash/fp/pickBy';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import pick from 'lodash/pick';
import some from 'lodash/some';
import take from 'lodash/take';
import { all, call, throttle, put, takeLatest } from 'redux-saga/effects';

const mapValues = mv.convert({ cap: false });

import {
  getFriendLeaderboard, getFriends, getGameAchievements, getLeaderboardTop, 
  getUserState, incrementStats, postToLeaderboard, RECENT_ACTIVITY_LEADERBOARD,
  TOTAL_EXERCISES_LEADERBOARD
} from '../api/braincloud-api';
import { getStatistics } from '../api/exercise';
import { loadSelf } from '../api/me';
import { saveAchievements } from '../api/social';
import {
  errorOccurred, 
  POST_TO_ACTIVITY_BOARD, 
  RECORD_ACHIEVEMENTS, 
  showAchievementToast, 
  SYNC_STATS,
  SYNC_STATS_THROTTLED,
  recordAchievements as recordAchievementsAction,
} from './actions';

import { massageSocialLeaderboard } from '../helpers/heroboard';

function* syncGameStats() {

  // there are two sources of stats:
  // - our backend
  // - brainCloud backend
  // this function fetches data from these two sources
  // and then updates the player's stats

  try {

    const [ 
      backendStats, 
      friendsList, 
      friendLeaderboard,
      globalLeaderboard,
      me,
    ] = yield all([
      // data from our backend
      call(getStatistics),

      // data from braincloud
      call(getFriends),
      call(getFriendLeaderboard, TOTAL_EXERCISES_LEADERBOARD),
      call(getLeaderboardTop, TOTAL_EXERCISES_LEADERBOARD, 5),

      // who am I? (includes current stats)
      call(getUserState),
    ]);

    const { profileId, statistics } = me;

    // Am I on top of my friends leaderboard?
    const social_leaderboard = massageSocialLeaderboard(friendLeaderboard?.social_leaderboard || []);

    // We always appear in the leaderboard even if there are null scores,
    // so we need to only count it if there is an actual score.
    const has_been_friends_leader = 
      social_leaderboard?.[0]?.playerId === profileId && 
      social_leaderboard?.[0]?.score !== null;

    // Am I in the global top 5?
    const top5 = take(globalLeaderboard.leaderboard, 5);
    const has_been_in_global_top_five = some(top5, { playerId: profileId });

    const currentStats = {
      ...backendStats,

      total_friends: friendsList?.friends?.length || 0,
      has_been_friends_leader: has_been_friends_leader ? 1 : 0,
      has_been_in_global_top_five: has_been_in_global_top_five ? 1 : 0,
    };

    // for every stat defined on the brainCloud server,
    // calculate the diff with the user's current exercises
    const diffs = flow(
      mapValues((value, stat) => {

        // this might happen if we define a stat in brainCloud
        // but haven't implemented the corresponding calculation
        // on our backend yet
        if (!has(currentStats, stat)) return null;

        return (currentStats[stat] || 0) - value;
      }),
      pickBy(x => isNumber(x) && x > 0)
    )(statistics || {});

    if (isEmpty(diffs)) return;

    const result = yield call(incrementStats, diffs);

    // when we increment stats, brainCloud will tell us if 
    // there are any new achievements
    const achievements = result?.rewards?.playerAchievements || [];

    if (!isEmpty(achievements)) {
      yield put(showAchievementToast(achievements));

      // send achievements to our backend
      yield put(recordAchievementsAction(achievements));
    }
  } catch (e) {
    console.error('Error syncing stats', e);
    yield put(errorOccurred(e));
  }
}

function* postToActivityBoard() {
  try {

    const me = yield call(loadSelf);
    const allowSocial = me.family?.family_preference?.allow_social;

    // only appear on leaderboards if we've opted in
    if (!allowSocial) return;

    const currentTime = Date.now();

    yield call(postToLeaderboard, RECENT_ACTIVITY_LEADERBOARD, currentTime);
    yield call(postToLeaderboard, TOTAL_EXERCISES_LEADERBOARD, 1);

    // TODO show toast notification if you're in the Top N of a leaderboard?

  } catch (e) {
    console.error('Error posting to leaderboard', e);
    yield put(errorOccurred(e));
  }
}

function* recordAchievements(action) {
  // When this user gets an achievement, we need to 
  // look up the metadata for the friendly name so that 
  // we can record it in the backend. Our backend doesn't 
  // have any access to the braincloud API, which is why we 
  // need to do this in such a convoluted way.

  const { achievements: achieved } = action;
  try {
    const { achievements } = yield call(getGameAchievements);

    const result = flow(
      filter(({ achievementId }) => achieved.includes(achievementId)),
      map((a) => pick(a, ['achievementId', 'title']))
    )(achievements);

    yield call(saveAchievements, result);

  } catch (e) {
    console.error('Failed to record achievements', e);
    yield put(errorOccurred(e));
  }
}

export default function* rootSaga() {
  yield all([

    // there's a throttled version of this action
    // e.g. when user mashes the Like buttons, we don't want to send too many requests
    yield takeLatest(SYNC_STATS, syncGameStats),
    yield throttle(15000, SYNC_STATS_THROTTLED, syncGameStats),

    yield takeLatest(POST_TO_ACTIVITY_BOARD, postToActivityBoard),
    yield takeLatest(RECORD_ACHIEVEMENTS, recordAchievements),
  ]);
}