import {
  SHOW_ACHIEVEMENT_TOAST,
  HIDE_TOAST,
  ERROR_OCCURRED,
  SET_HAS_UNREAD_CHAT_MESSAGES,
  ROUTE_CHANGED
} from './actions';
import produce from 'immer';

const initialState = {
  toast: null,
  error: null,
  hasUnreadChatMessages: false,
  pathname: window?.location?.pathname ?? null,
};

const rootReducer = (state = initialState, action) => {

  switch (action.type) {
    case SHOW_ACHIEVEMENT_TOAST:
      return produce(state, (draft) => {
        draft.toast = {
          type: SHOW_ACHIEVEMENT_TOAST,
          achievements: action.achievements,
        };

      });

    case HIDE_TOAST:
      return produce(state, (draft) => {
        draft.toast = null;
      });

    case ERROR_OCCURRED:
      return produce(state, (draft) => {
        draft.error = action.error;
      });

    case SET_HAS_UNREAD_CHAT_MESSAGES:
      return produce(state, (draft) => {
        draft.hasUnreadChatMessages = action.hasUnreadChatMessages;
      });

    case ROUTE_CHANGED:
      return produce(state, (draft) => {
        draft.pathname = action.pathname;
      });

    default:
      return state;
  }
};

export default rootReducer;
