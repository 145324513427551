// a component for a "check/uncheck all" button for a list of checkboxes
export default function checkAll(length) {
  return {
    checks: new Array(length).fill(false),
    allChecked() {
      return this.checks.every(c => c);
    },
    anyChecked() {
      return this.checks.some(c => c);
    },
    toggle() {
      if (this.allChecked()) {
        this.checks.fill(false);
      } else {
        this.checks.fill(true);
      }
    }
  };
}