// brainCloud's data interface is not consistent >:-(
// have to do some massaging to get the social leaderboard data 
// to have the same structure as global leaderboards

import sortBy from 'lodash/fp/sortBy';
import map from 'lodash/fp/map';
import flow from 'lodash/fp/flow';

const mapWithIndex = map.convert({ cap: false });

export const massageSocialLeaderboard = (social_leaderboard) => {

  return flow(
    sortBy([
      // sort by putting null scores at the end,
      // then in descending order
      ({ score }) => {
        if (score === null) return Number.MAX_VALUE;
        return -1 * score;
      }
    ]),
    mapWithIndex((entry, index) => {
      // add the rank and "name" attribute
      return {
        ...entry,
        rank: index + 1,
        name: entry.playerName,
      }
    })
  )(social_leaderboard);
};