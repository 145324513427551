import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer, 
  applyMiddleware(sagaMiddleware)
);

sagaMiddleware.run(rootSaga);

if (window.Cypress) {
  console.log('Setting redux store for cypress');
  window.__store__ = store;
}

export default store;