import _ from 'lodash';
import originalFetch from 'cross-fetch';
import retry from 'fetch-retry';

const fetch = retry(originalFetch, {
  retries: 5,
  retryDelay: (attempt) => {
    return Math.pow(2, attempt) * 200;
  },
});

async function handleError(res) {
  // converts an error HTTP response from fetch() to 
  // a thrown exception
  
  if (res.ok) {
    // success
    return res;
  } else {
    
    const { status } = res;

    const body = await res.text();
    let errMsg = '';
    try {
      const parsed = JSON.parse(body);
      errMsg = _.get(parsed, 'error', null);
    } catch (e) {
      errMsg = 'Unknown';
    }
    
    throw { status, errMsg };
  }
}

export function get(url) {
  return fetch(url).then(handleError);
}

export function put(url, body) {
  return fetch(url, { 
    method: "PUT",
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  })
  .then(handleError);
}

export function post(url, body) {
  return fetch(url, { 
    method: "POST",
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  })
  .then(handleError);
}

export function postFile(url, formData) {
  return fetch(url, {
    method: "POST",
    body: formData
  })
  .then(handleError);
}

export function del(url) {
  return fetch(url, { method: "DELETE" }).then(handleError);
}

