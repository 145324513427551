import React, { useEffect, useState } from 'react';
import { MAX_SCENARIOS } from '../query/queryKeys';
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { 
  getDefaultScenarioForFamily, 
  getScenarios as getScenariosCoach,
  createScenario as createScenarioCoach,
  updateScenario as updateScenarioCoach,
  deleteScenario as deleteScenarioCoach,
  saveDefaultScenarioForFamily,
} from '../api/coach';
import { 
  getDefaultScenario, 
  getScenarios as getScenariosOwner,
  createScenario as createScenarioOwner,
  updateScenario as updateScenarioOwner,
  deleteScenario as deleteScenarioOwner,
  saveDefaultScenario,
} from '../api/scenarios';
import { useErrorHandler } from 'react-error-boundary';
import produce from 'immer';
import compact from 'lodash/compact';

const COLOURS = [
  '#F18F3D',
  '#F6B9D5',
  '#E1313E',
  '#D5CCE3',
  '#FBDDC5',
  '#FCEAF2',
  '#F6C1C5',
];

export const DEFAULT_SCENARIO_ID = 'DEFAULT_SCENARIO';

// in the backend, a "null" scenario means that the default scenario
// is used
export function normalizeScenarioId(scenarioId) {
  if (scenarioId === DEFAULT_SCENARIO_ID || !scenarioId) {
    return null;
  } else {
    return scenarioId;
  }
}

export function normalizeScenario(scenario) {
  if (scenario?.id === DEFAULT_SCENARIO_ID) return null;
  return scenario;
}

export function normalizeExercise(exercise) {
  const { scenario, ...rest } = exercise;
  return {
    scenario_id: normalizeScenarioId(scenario?.id),
    ...rest
  };
}

// for older class-based components
// (this is read-only)
export function withScenarios(Component, familyId) {
  function C(props) {
    const { scenarios, defaultScenario, loading } = useScenarios(familyId);
    return (
      <Component 
        scenarios={scenarios} 
        defaultScenario={defaultScenario}
        scenariosIsLoading={loading}
        {...props}
      />
    );
  }

  return C;
}

const SCENARIOS = 'Scenarios';
const scenariosKey = 
  (familyId) => (compact([ SCENARIOS, familyId ]));

const DEFAULT_SCENARIO = 'DefaultScenario';
const defaultScenarioKey =
  (familyId) => (compact([ DEFAULT_SCENARIO, familyId ]));

const ALL_SCENARIOS = 'AllScenarios';
const allScenariosKey =
  (familyId) => (compact([ ALL_SCENARIOS, familyId ]));

export function getDefaultScenarioName(scenarios) {
  return scenarios.find((s) => s.id === DEFAULT_SCENARIO_ID)?.tag;
}

export default function useScenarios(familyId) {
  // If familyId is given, this is for a coach managing
  // the scenarios for one of their client families.
  // If not, then this is for a Heroes account managing their 
  // own scenarios.

  const queryClient = useQueryClient();

  const [ cannotDelete, setCannotDelete ] = useState(null);

  const queryFn = familyId ?
    () => getScenariosCoach(familyId, true) :
    getScenariosOwner;
  
  const defaultScenarioFn = familyId ?
    () => getDefaultScenarioForFamily(familyId) :
    getDefaultScenario;

  const maxScenariosQuery = useQuery({ queryKey: MAX_SCENARIOS });

  const queryKey = scenariosKey(familyId);

  const scenariosQuery = useQuery({
    queryKey,
    queryFn,
    refetchOnWindowFocus: false,
    staleTime: 20000,
  });

  const defaultScenarioQuery = useQuery({
    queryKey: defaultScenarioKey(familyId),
    queryFn: defaultScenarioFn,
    refetchOnWindowFocus: false,
    staleTime: 20000,
  });

  const loading = 
    scenariosQuery?.isLoading || 
    defaultScenarioQuery?.isLoading ||
    maxScenariosQuery?.isLoading;

  // this query combines the fetched data into the format
  // that consumers will use
  const allScenariosQuery = useQuery({ 
    queryKey: allScenariosKey(familyId),
    enabled: !loading,
    staleTime: 5000,
    retry: false,
    queryFn: () => {
      const defaultScenario = defaultScenarioQuery.data.default_scenario;
      const customScenarios = scenariosQuery.data;

      // here, we have fetched all scenarios, including archived ones,
      // we still need those to display historical data

      const scenarios = [ 
        { tag: defaultScenario, id: DEFAULT_SCENARIO_ID }, 
        ...customScenarios 
      ];

      const scenarioLabels = {};
      scenarios.forEach((s, index) => {
        scenarioLabels[s.id] = {
          colour: COLOURS[index % COLOURS.length],
          tag: s.tag
        };
      });

      // when returning "scenarios" here, we only expose the active (non-archived) 
      // ones, whereas the "scenarioLabels" includes all
      return { 
        scenarios: scenarios.filter((s) => !s.is_archived), 
        scenarioLabels 
      };
    }
  });

  useEffect(() => {
    if (!loading) {
      allScenariosQuery.refetch();
    }
  }, [ scenariosQuery.data, defaultScenarioQuery.data, loading ]);

  function invalidate() {
    queryClient.invalidateQueries([ SCENARIOS ]);
    queryClient.invalidateQueries([ DEFAULT_SCENARIO ]);
  }

  useErrorHandler(scenariosQuery?.error);
  useErrorHandler(defaultScenarioQuery?.error);
  useErrorHandler(maxScenariosQuery?.error);
  useErrorHandler(allScenariosQuery?.error);

  const handleError = useErrorHandler();

  async function createScenario(tag) {
    if (familyId) {
      await createScenarioCoach(familyId, tag);
    } else {
      await createScenarioOwner(tag);
    }
  }
  
  async function editScenario(id, tag) {
    if (familyId) {
      if (id === DEFAULT_SCENARIO_ID) {
        await saveDefaultScenarioForFamily(familyId, tag);
      } else {
        await updateScenarioCoach(familyId, id, { tag });
      }
    } else {
      if (id === DEFAULT_SCENARIO_ID) {
        await saveDefaultScenario(tag);
      } else {
        await updateScenarioOwner(id, { tag });
      }
    }
  }

  async function deleteScenario(scenario) {
    try {
      if (familyId) {
        await deleteScenarioCoach(familyId, scenario.id);
      } else {
        await deleteScenarioOwner(scenario.id);
      }
      setCannotDelete(null);
    } catch (e) {
      if (e.status === 403) {
        // 403 Forbidden means that the scenario is in use
        setCannotDelete(scenario.tag);
      } else {
        throw e;
      }
    }
  }

  const createMutation = useMutation({
    mutationFn: ({ tag }) => createScenario(tag),
    onSettled: invalidate,
    onError: handleError,
  });

  const editMutation = useMutation({
    mutationFn: ({ id, tag }) => editScenario(id, tag),
    onMutate: ({ id, tag }) => {
      // optimistic update to avoid UI getting out of sync
      if (id === DEFAULT_SCENARIO_ID) {
        queryClient.setQueryData(defaultScenarioKey, { default_scenario: tag });
      } else {
        queryClient.setQueryData(queryKey, produce((draft) => {
          const scenario = draft.find((s) => s.id === id);
          scenario.tag = tag;
        }));
      }
    },
    onSettled: invalidate,
    onError: handleError,
  });

  const deleteMutation = useMutation({ 
    mutationFn: ({ scenario }) => deleteScenario(scenario),
    onSettled: invalidate,
    onError: handleError,
  });


  const { scenarios, scenarioLabels } = allScenariosQuery.data ?? { scenarios: [], scenarioLabels: {} };

  return {
    scenarios,
    scenarioLabels,
    defaultScenario: defaultScenarioQuery?.data?.default_scenario,
    loading,
    submitting: createMutation?.isLoading,
    createScenario: (tag) => createMutation.mutate({ tag }),
    editScenario: (id, tag) => editMutation.mutate({ id, tag }),
    deleteScenario: (scenario) => deleteMutation.mutate({ scenario }),
    cannotDelete,
    maxScenarios: maxScenariosQuery?.data?.max_scenarios 
  }; 

}