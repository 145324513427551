import { post, get } from "./requests";

export function login(username, password) {
  return post("/login", { username, password }).then(res => res.json());
}

export function resetPassword(email) {
  return post("/reset_password", { email });
}

export function newPassword(password, password_confirmation, token) {
  return post("/new_password", { password, password_confirmation, token });
}

export function createPassword(username, first_name, last_name, dog, password, password_confirmation) {
  return post("/v2/create_password", {
    username, first_name, last_name, dog, password, password_confirmation,
  }).then(res => res.json());
}

export function getTerms() {
  return get("/terms").then(res => res.json());
}

export function register(
  first_name,
  last_name,
  dog_name,
  password,
  password_confirmation,
  token,
  email,
) {
  return post("/register", {
    first_name,
    last_name,
    dog_name,
    password,
    password_confirmation,
    token,
    email,
  });
}

export function logout() {
  return post("/logout");
}

export function verifyEmail(username) {
  return post('/v2/verify_email', { username }).then(res => res.json());
}
