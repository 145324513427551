import store from '../redux/store';
import queryString from 'query-string';

// When React client-side routing occurs, it will update the Redux store with the new path.
// We listen for changes here and update the header icon by requesting the SVG from the server.
export default function headerIcon() {
  return {
    unsubscribe: null,
    icon: null,
    currentPath: null,
    init() {
      this.unsubscribe = store.subscribe(async () => {
        const state = store.getState();
        const { pathname } = state;

        // don't do anything if it hasn't changed
        if (pathname === this.currentPath) return;

        const url = queryString.stringifyUrl({
          url: '/header-icon',
          query: { path: pathname }
        });

        try {
          const response = await fetch(url);
          const data = await response.text();
          this.icon = data;
          this.currentPath = pathname;

        } catch (error) {
          // nothing
          this.icon = null;
        }
      });
    },
    destroy() {
      this.unsubscribe?.();
    }
  };
}
