import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';

import '@hotwired/turbo-rails';
import htmx from 'htmx.org';
import queryClient from '../query/queryClient';

import Alpine from 'alpinejs';
import checkAll from '../alpine/checkAll';
import checkout from '../alpine/checkout';
import collectPayment from '../alpine/collectPayment';
import changeLocale from '../alpine/changeLocale';
import headerIcon from '../alpine/headerIcon';
import chatNotification from '../alpine/chatNotification';
import navigation from '../alpine/navigation';

import store from '../redux/store';
import { routeChanged } from '../redux/actions';
import { setCredentials, bcAuthenticate, setUserName, setFriendData } from '../api/braincloud-api';

export default function createEntrypoint(Component, { initializeBraincloud } = {}) {

  if (initializeBraincloud) {

    const meta = document.head.querySelector('meta[name="application-name"]');
    if (meta?.dataset?.gameCreds) {
      const gameCreds = JSON.parse(meta.dataset.gameCreds);
      const dogs = JSON.parse(meta.dataset.dogs);
      const me = JSON.parse(meta.dataset.user);

      setCredentials(gameCreds);
      bcAuthenticate()
        .then((response) => {
          console.log('Successfully authenticated with braincloud', response);

          const { playerName, summaryFriendData } = response;

          // if the brainCloud user profile does not yet have
          // the dog's name, update it
          const dogName = dogs[0]?.name;
          if (playerName !== dogName) {
            // update the dog name
            setUserName(dogName);
          }

          // use this field in the brainCloud profile to
          // store the family's ID
          // For social features like leaderboards, each user
          // can see this field for other users. We'll use this
          // to look up data in our own backend
          if (!summaryFriendData) {
            setFriendData({
              id: me.family.id,
            });
          }
        })
        .catch((e) => {
          console.log('Failed to authenticate with braincloud', e);
        });
    }
  }

  window.htmx = htmx;

  Alpine.data('checkAll', checkAll);
  Alpine.data('checkout', checkout);
  Alpine.data('collectPayment', collectPayment);
  Alpine.data('changeLocale', changeLocale);
  Alpine.data('headerIcon', headerIcon);
  Alpine.data('chatNotification', chatNotification);
  Alpine.data('navigation', navigation);

  // a message for a confirmation modal
  Alpine.store('confirmation', {
    element: null,
    message: '',
  });
  Alpine.start();

  document.addEventListener('DOMContentLoaded', () => {
    const container = document.getElementById('react-container');
    if (container) {
      render(<Component />, container);
    }
  });

  // after Turbo navigates and re-renders the body,
  // tell the React app to re-render so that it updates according
  // to the new page URL
  document.addEventListener('turbo:before-render', () => {
    const container = document.getElementById('react-container');
    if (container) {
      unmountComponentAtNode(container);
    }
  });
  document.addEventListener('turbo:render', () => {
    const container = document.getElementById('react-container');
    if (container) {
      render(<Component />, container);
    }

    // let HTMX attach to any new elements rendered by Turbo
    htmx.process(document.body);

    // update the redux store with the pathname so that any UI
    // that depends on the pathname can be re-rendered
    store.dispatch(routeChanged(window.location.pathname));
  });

  window.addEventListener('load', () => {

    // eslint-disable-next-line
    if (process.env.NODE_ENV === 'development') return;

    if (navigator.serviceWorker) {
      navigator.serviceWorker.register('/service-worker.js').then(registration => {
        console.log('ServiceWorker registered: ', registration);
      }).catch(registrationError => {
        console.log('Service worker registration failed: ', registrationError);
      });
    }
  });

  if (window.Cypress) {
    window.__queryClient__ = queryClient;
  }

}
