import { get, put, post, del } from "./requests";

export function getScenarios() {
  return get("/owner/v1/scenarios?all=true").then(res => res.json());
}

export function createScenario(payload) {
  return post("/owner/v1/scenarios", { scenario: payload });
}

export function updateScenario(id, payload) {
  return put(`/owner/v1/scenarios/${id}`, { scenario: payload });
}

export function deleteScenario(id) {
  return del(`/owner/v1/scenarios/${id}`);
}

export function getDefaultScenario() {
  return get("/owner/v1/default_scenario").then(res => res.json());
}

export function saveDefaultScenario(name) {
  return put("/owner/v1/default_scenario", { default_scenario: name });
}
