import { get, put, del } from './requests';
import queryString from 'query-string';

export function getFamilies() {
  return get('/admin/v1/families').then(res => res.json());
}

export function getFamily(id) {
  return get(`/admin/v1/families/${id}`).then(res => res.json());
}

export function updateFamily(id, payload) {
  return put(`/admin/v1/families/${id}`, { family: payload });
}

export function deleteFamily(id) {
  return del(`/admin/v1/families/${id}`);
}

export function getPending() {
  return get('/admin/v1/pending_users').then(res => res.json());
}

export function getPendingForFamily(family_id) {
  const url = queryString.stringifyUrl({
    url: '/admin/v1/pending_users',
    query: { family_id }
  },
  { skipNull: true });

  return get(url).then(res => res.json());
}

export function getPendingForCoach(coach_id) {
  const url = queryString.stringifyUrl({
    url: '/admin/v1/pending_users',
    query: { coach_id }
  },
  { skipNull: true });

  return get(url).then(res => res.json());
}

export function getPendingDetail(id) {
  return get(`/admin/v1/pending_users/${id}`).then(res => res.json());
}

export function deletePending(id) {
  return del(`/admin/v1/pending_users/${id}`);
}

export function getExercises(id) {
  return get(`/admin/v1/families/${id}/exercises`).then(res => res.json());
}