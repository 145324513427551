import queryString from "query-string";

import { get, post, put, del } from "./requests";
import { normalizeScenarioId } from "../hooks/useScenarios";

export function getLicence() {
  return get("/coach/v1/license").then((res) => res.json());
}

export function getNotifications() {
  return get("/coach/v3/notifications").then((res) => res.json());
}

export function getBestFitLines(id, byScenario) {
  const url = queryString.stringifyUrl({
    url: `/coach/v1/families/${id}/graphs/bestfit`,
    query: {
      by_scenario: byScenario ? true : null
    }
  }, { skipNull: true });

  return get(url).then((res) => res.json());
}

export function getPendingClientsDetails(id) {
  return get(`/coach/v1/pending_families/${id}`).then((res) => res.json());
}

export function getClientsDetails(id) {
  return get(`/coach/v1/families/${id}`).then((res) => res.json());
}

export function deleteExerciseNotification(id) {
  return del(`/coach/v3/notifications/exercise/${id}`);
}

export function createClient(family) {
  return post("/coach/v1/families", {
    family,
  });
}

export function updateFeedback(payload) {
  return put(
    `/coach/v3/families/${payload.id}/exercises/${payload.exercise_id}`,
    { exercise: payload }
  );
}

export function updateClient(payload) {
  return put(`/coach/v1/families/${payload.id}`, payload);
}

export function getExercisesForFamily({ descending = false, id, type = 'DepartureExercise' } = {}) {
  const url = queryString.stringifyUrl({
    url: `/coach/v3/families/${id}/exercises`,
    query: {
      descending: descending ? 1 : null,
      type
    }
  }, { skipNull: true });
  return get(url).then((res) => res.json());
}

export function getExerciseDetailsForFamily(id, exercise_id) {
  return get(`/coach/v3/families/${id}/exercises/${exercise_id}`).then((res) =>
    res.json()
  );
}

// generate plan
export async function getLatestExerciseForFamily(family_id, type) {
  const exercises = await getExercisesForFamily({ descending: true, id: family_id, type });
  return exercises?.[0] || null;
}

export function getGeneratedPlan(family_id, scenario_id) {
  const url = queryString.stringifyUrl({
    url: `/coach/v1/families/${family_id}/training_target`,
    query: {
      scenario: normalizeScenarioId(scenario_id),
    },
  }, { skipNull: true });
  return get(url).then((res) => res.json());
}

export function getGeneratedSteps(family_id, target_duration) {
  const url = queryString.stringifyUrl({
    url: `/coach/v1/families/${family_id}/training_steps`,
    query: {
      target_duration,
    },
  });
  return get(url).then((res) => res.json());
}

export function getPlansForFamily(family_id, type) {
  const url = queryString.stringifyUrl({
    url: `/coach/v3/families/${family_id}/plans`,
    query: {
      type
    }
  });
  return get(url).then((res) => res.json());
}

export function createPlan(family_id, plan, type) {
  return post(`/coach/v3/families/${family_id}/plans`, {
    type,
    [type === 'DepartureExercise' ? 'multiplan' : 'plan'] : plan,
  });
}

export function deletePlans(family, type) {
  const url = queryString.stringifyUrl({
    url: `/coach/v3/families/${family}/plans`,
    query: {
      type
    }
  });
  return del(url);
}

// Scenarios
export function getScenarios(family_id, all) {
  const url = queryString.stringifyUrl({
    url: `/coach/v1/families/${family_id}/scenarios`,
    query: {
      all,
    },
  });
  return get(url).then((res) => res.json());
}

export function createScenario(family_id, scenario) {
  return post(`/coach/v1/families/${family_id}/scenarios`, {
    scenario,
  });
}

export function deleteScenario(family_id, scenario_id) {
  return del(`/coach/v1/families/${family_id}/scenarios/${scenario_id}`);
}

export function updateScenario(family_id, scenario_id, payload) {
  return put(`/coach/v1/families/${family_id}/scenarios/${scenario_id}`, {
    scenario: payload,
  });
}

export function getDefaultScenarioForFamily(family_id) {
  return get(`/coach/v1/families/${family_id}/default_scenario`)
    .then(res => res.json());
}

export function saveDefaultScenarioForFamily(family_id, name) {
  return put(`/coach/v1/families/${family_id}/default_scenario`, {
    default_scenario: name
  });
}

// retrieve list of training levels (for DIAB, crate, mat, etc.)
export function getLevels(type, split) { 
  const url = queryString.stringifyUrl({
    url: '/coach/v3/levels',
    query: {
      type,
      split
    }
  }, { skipNull: true });
  return get(url).then((res) => res.json());
}

export function getClientsForDashboard() {
  return get("/coach/v3/dashboard").then((res) => res.json());
}