import React, { Suspense, useEffect } from 'react';
import useI18n from '../hooks/useI18n';

import '../helpers/fonticons';
import 'bootstrap/dist/js/bootstrap.min.js';

import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import queryClient from '../query/queryClient';
import ErrorBoundary from '../components/errorpages/ErrorBoundary';
import { BrowserRouter, useHistory } from 'react-router-dom';
import FullPageSpinner from '../components/common/FullPageSpinner';
import ProContext from '../contexts/pro';
import store from '../redux/store';
import { Provider, useDispatch } from 'react-redux';
import { routeChanged } from '../redux/actions';

function isPro() {
  const meta = document.head.querySelector('meta[name=application-name]');
  return meta?.dataset?.isProTheme === 'true';
}

function RouteListener() {
  // Use the Redux store to broadcast changes when client-side routing occurs.
  // The Redux store is used to talk to Javascript components outside of the React application,
  // e.g. Alpine scripts that sit in the page header or navigation bar.

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = history.listen((event) => {
      const { pathname } = event;
      dispatch(routeChanged(pathname));
    });
    return unsubscribe;
  }, []);

  return null;
}

export default function AppRoot({ children, home }) {

  const { ready } = useI18n();

  if (!ready) return <FullPageSpinner />;

  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary home={home ?? '/'}>
        <BrowserRouter>

          <Suspense fallback={<FullPageSpinner />}>
            <ProContext.Provider value={isPro()}>
              <Provider store={store}>
                <RouteListener />
                {children}
              </Provider>
            </ProContext.Provider>
          </Suspense>

        </BrowserRouter>
      </ErrorBoundary>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
