import { QueryClient } from '@tanstack/react-query';
import { 
  ADMIN_AI_PREVIEW_LIST,
  AI_LOGS, 
  ADMIN_AI_QUESTIONS, 
  AI_INITIAL_SUMMARY, 
  FRIENDS, 
  HELP_CHAT, 
  INVITES,
  ADMIN_AI_SETTINGS,
  AI_QUESTIONS,
  ADMIN_AI_PREVIEW_FAMILY, 
  ADMIN_FAMILY_DETAIL,
  ADMIN_AI_ASK_QUESTION,
  AI_EXPANDED_SUMMARY,
  MAX_SCENARIOS,
  ACTIVE_CAMPAIGN,
  ADMIN_AI_RECENTS,
  ADMIN_AI_HISTORY,
  TERMS_AND_CONDITIONS,
  PRICES,
  ME,
  CHAT_TOKEN,
  DOGS,
  GAME_CREDS,
  ADMIN_TRAINER_DETAIL,
  ADMIN_TRAINER_PENDING,
} from './queryKeys';

import { 
  getAISettings,
  getAIQuestions,
  getPreviewList,
  getFamilyPreview,
  getAIRecents,
  getAIHistory,
  getTrainerDetail,
} from '../api/admin';
import { getFamily, getPendingForCoach } from '../api/family';
import { getActiveCampaign, getHelpChat, getMaxScenarios, loadChatToken, loadDogs, loadGameCredentials, loadSelf } from '../api/me';
import { getAILogs, getAIInitialSummary, getQuestions, getAIExpandedSummary } from "../api/ai";
import { getTerms } from '../api/login';
import { getPrices } from '../api/purchase';

const queryClient = new QueryClient();

queryClient.setQueryDefaults(FRIENDS, {
  staleTime: 1000,
});

queryClient.setQueryDefaults(INVITES, {
  staleTime: 1000,
});

queryClient.setQueryDefaults(ADMIN_AI_PREVIEW_LIST, {
  queryFn: ({ queryKey }) => {
    const random = queryKey[1];
    if (random) return getPreviewList(true);
    return getPreviewList(null);
  },
  refetchOnWindowFocus: false,
});

queryClient.setQueryDefaults(ADMIN_AI_PREVIEW_FAMILY, {
  queryFn: ({ queryKey }) => getFamilyPreview(queryKey[1]),
  refetchOnWindowFocus: false,
});

queryClient.setQueryDefaults(ADMIN_FAMILY_DETAIL, {
  queryFn: ({ queryKey }) => getFamily(queryKey[1]),
  refetchOnWindowFocus: false,
});

queryClient.setQueryDefaults(HELP_CHAT, {
  queryFn: getHelpChat,
  staleTime: Infinity,
  refetchOnWindowFocus: false,
  retry: false,
});

queryClient.setQueryDefaults(ACTIVE_CAMPAIGN, {
  queryFn: getActiveCampaign,
  staleTime: Infinity,
  refetchOnWindowFocus: false,
  retry: false,
});

queryClient.setQueryDefaults(AI_INITIAL_SUMMARY, {
  queryFn: getAIInitialSummary,
  staleTime: 30000,
  refetchOnWindowFocus: false,
  retry: false,
});
queryClient.setQueryDefaults(AI_EXPANDED_SUMMARY, {
  queryFn: getAIExpandedSummary,
  staleTime: 30000,
  refetchOnWindowFocus: false,
  retry: false,
});

queryClient.setQueryDefaults(AI_LOGS, {
  queryFn: getAILogs,
  staleTime: 5000,
  refetchOnWindowFocus: false,
});

queryClient.setQueryDefaults(AI_QUESTIONS, {
  queryFn: getQuestions,
  staleTime: 10000,
  refetchOnWindowFocus: false,
});

queryClient.setQueryDefaults(ADMIN_AI_SETTINGS, {
  queryFn: getAISettings,
  refetchOnWindowFocus: false,
});

queryClient.setQueryDefaults(ADMIN_AI_QUESTIONS, {
  queryFn: getAIQuestions,
  refetchOnWindowFocus: false,
});

queryClient.setQueryDefaults(ADMIN_AI_ASK_QUESTION, {
  refetchOnWindowFocus: false,
  staleTime: 10000,
  retry: false,
});

queryClient.setQueryDefaults(MAX_SCENARIOS, {
  queryFn: getMaxScenarios,
  refetchOnWindowFocus: false,
  staleTime: Infinity,
});

queryClient.setQueryDefaults(ADMIN_AI_RECENTS, {
  queryFn: getAIRecents,
  refetchOnWindowFocus: false,
  staleTime: 5000,
});

queryClient.setQueryDefaults(ADMIN_AI_HISTORY, {
  queryFn: ({ queryKey }) => getAIHistory(queryKey[1]),
  refetchOnWindowFocus: false,
  staleTime: 5000,
});

queryClient.setQueryDefaults(TERMS_AND_CONDITIONS, {
  queryFn: getTerms,
  refetchOnWindowFocus: false,
  staleTime: Infinity,
});

queryClient.setQueryDefaults(PRICES, {
  queryFn: ({ queryKey }) => getPrices(queryKey[1]?.couponId, queryKey[1]?.role),
  refetchOnWindowFocus: false,
  staleTime: Infinity,
});

queryClient.setQueryDefaults(ME, {
  queryFn: loadSelf,
  staleTime: 10000,
});

queryClient.setQueryDefaults(DOGS, {
  queryFn: loadDogs,
  staleTime: 10000,
});

queryClient.setQueryDefaults(GAME_CREDS, {
  queryFn: loadGameCredentials,
  refetchOnWindowFocus: false,
  staleTime: Infinity,
});

queryClient.setQueryDefaults(CHAT_TOKEN, {
  queryFn: loadChatToken,
  staleTime: Infinity,
});

queryClient.setQueryDefaults(ADMIN_TRAINER_DETAIL, {
  queryFn: ({ queryKey }) => getTrainerDetail(queryKey[1]),
  refetchOnWindowFocus: false,
});

queryClient.setQueryDefaults(ADMIN_TRAINER_PENDING, {
  queryFn: ({ queryKey }) => getPendingForCoach(queryKey[1]),
  refetchOnWindowFocus: false,
});

export default queryClient;
