export const SYNC_STATS = 'SYNC_STATS';
export const SYNC_STATS_THROTTLED = 'SYNC_STATS_THROTTLED';
export const SHOW_ACHIEVEMENT_TOAST = 'SHOW_ACHIEVEMENT_TOAST';
export const HIDE_TOAST = 'HIDE_TOAST';
export const POST_TO_ACTIVITY_BOARD = 'POST_TO_ACTIVITY_BOARD';
export const ERROR_OCCURRED = 'ERROR_OCCURRED';
export const RECORD_ACHIEVEMENTS = 'RECORD_ACHIEVEMENTS';
export const SET_HAS_UNREAD_CHAT_MESSAGES = 'SET_HAS_UNREAD_CHAT_MESSAGES';
export const ROUTE_CHANGED = 'ROUTE_CHANGED';

// this action can be triggered by a quick succession of user inputs
// e.g. hitting the Like button. For those cases, pass the throttle parameter
// to prevent sending too many requests all at once.
export const syncGameStats = ({ throttle } = {}) => ({
  type: throttle ? SYNC_STATS_THROTTLED : SYNC_STATS
});

export const postToActivityBoard = () => ({
  type: POST_TO_ACTIVITY_BOARD
});

export const showAchievementToast = (achievements) => ({
  type: SHOW_ACHIEVEMENT_TOAST,
  achievements,
});

export const hideToast = () => ({
  type: HIDE_TOAST
});

export const errorOccurred = (error) => ({
  type: ERROR_OCCURRED,
  error
});

export const recordAchievements = (achievements) => ({
  type: RECORD_ACHIEVEMENTS,
  achievements
});

export const setHasUnreadChatMessages = (hasUnreadChatMessages) => ({
  type: SET_HAS_UNREAD_CHAT_MESSAGES,
  hasUnreadChatMessages,
});

export const routeChanged = (pathname) => ({
  type: ROUTE_CHANGED,
  pathname
});
