import Cookies from 'js-cookie';

export default function changeLocale() {
  return {
    changeLocale(locale) {
      Cookies.set('subthreshold_locale', locale);
      document.documentElement.lang = locale;
      window.location.reload();
    }
  };
}
