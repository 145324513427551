import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classNames from "classnames";

const getComponent = component => {
  if (component === "link") return "a";
  if (component === "router-link") return Link;
  return "button";
};

const Cta = ({
  href,
  onClick,
  children,
  disabled,
  type,
  role,
  target,
  className,
  as
}) => {
  const Component = getComponent(as);

  const getProps = () => {
    switch (as) {
      case "link": {
        return {
          href,
          target
        };
      }
      case "router-link": {
        return {
          to: href
        };
      }
      default:
        return {
          onClick,
          disabled,
          type,
          role
        };
    }
  };

  return (
    <Component className={classNames("cta", className)} {...getProps()}>
      {children}
    </Component>
  );
};

Cta.propTypes = {
  as: PropTypes.oneOf(["button", "router-link", "link"]),
  href: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  role: PropTypes.string,
  className: PropTypes.string,
  target: PropTypes.string
};

Cta.defaultProps = {
  as: "button",
  className: undefined,
  href: undefined,
  onClick: undefined,
  disabled: false,
  type: "button",
  role: "button",
  target: undefined
};

export default Cta;
