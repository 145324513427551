import { get, post } from "./requests";

export function getAIInitialSummary() {
  return get("/owner/v1/ai/initial_summary").then(res => res.json());
}

export function getAIExpandedSummary() {
  return get("/owner/v1/ai/expanded_summary").then(res => res.json());
}
export function getAILogs() {
  return get("/owner/v1/ai/logs").then(res => res.json());
}

export function getQuestions() {
  return get("/owner/v1/ai/questions").then(res => res.json());
}

export function askQuestion(question) {
  return post("/owner/v1/ai/ask", { question }).then(res => res.json());
}