import React from 'react';

import Spinner from 'react-bootstrap/Spinner';

class FullPageSpinner extends React.Component {
  
  render() {
    return (
      <div className="d-flex flex-row justify-content-center vh-100" data-testid="loading-spinner">
        <div className="flex-grow-1 align-self-center text-center">
          <Spinner animation="border" variant="secondary" />
        </div>
      </div>
    );
  }
  
}

export default FullPageSpinner;
