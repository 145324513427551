import { useState, useEffect } from 'react';
import i18n from '../helpers/i18n';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import ChainedBackend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';

export default function useI18n() {

  const [ ready, setReady ] = useState(false);

  useEffect(() => {

    i18n
      .use(initReactI18next)
      .use(LanguageDetector)
      .use(ChainedBackend)
      .init({
        load: 'languageOnly',
        fallbackLng: 'en',
        detection: {
          order: ['htmlTag'],
        },
        backend: {
          backends: [
            LocalStorageBackend,
            HttpBackend,
          ],
          backendOptions: [
            {
              // for LocalStorageBackend
              expirationTime: 15 * 24 * 60 * 60 * 1000, // 15 days
              // if version changes, it invalidates the cache and the translations
              // will be refetched from the server
              defaultVersion: 'v4.0.4',
            },
            {
              // for HttpBackend
              loadPath: '/translations?lang={{lng}}',
            }
          ]
        }
      })
      .then(() => {
        setReady(true);
      });

  }, []);

  return { ready };

}
