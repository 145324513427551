import { get, put, post, postFile, del } from "./requests";

export function loadSelf() {
  return get("/v1/me").then(res => res.json());
}

export function loadDogs() {
  return get("/owner/v1/dogs").then(res => res.json());
}

export function updateSelf(payload) {
  return put("/v1/me", { user: payload });
}

export function getFamily() {
  return get("/v1/me/family").then(res => res.json());
}

export function getPendingFamily() {
  return get("/v1/me/family/pending").then(res => res.json());
}

export function addFamilyMember(payload) {
  return post("/owner/v1/family_members", { user: payload });
}

export function removeFamilyMember(id) {
  return del(`/owner/v1/family_members/${id}`);
}

export function uploadProfilePic(file) {
  const formData = new FormData();
  formData.append('pic', file);

  return postFile("/v1/me/pic", formData);
}

export function loadGameCredentials() {
  return get("/v1/game_creds").then(res => res.json());
}

export function loadChatToken() {
  return get("/v1/chat_token").then(res => res.json());
}

export function getHelpChat() {
  return get("/v1/help_chat").then(res => res.text());
}

export function getActiveCampaign() {
  return get("/v1/active_campaign").then(res => res.text());
}

export function getMaxScenarios() {
  return get("/v1/max_scenarios").then(res => res.json());
}

