import store from '../redux/store';

// The React useChat hook will update the Redux store when it receives a message notification
// from the Twilio client. This script sits in the navigation bar and shows a badge when there
// is an unread message.
export default function chatNotification() {
  return {
    unsubscribe: null,
    hasUnread: false,
    init() {
      this.hasUnread = store.getState()?.hasUnreadChatMessages;

      this.unsubscribe = store.subscribe(() => {
        this.hasUnread = store.getState()?.hasUnreadChatMessages;
      });
    },
    destroy() {
      this.unsubscribe?.();
    }
  };
}
