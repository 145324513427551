import React from "react";
import { withTranslation } from "react-i18next";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Cta from "../common/Cta";
import ROUTES from "../../constants/routes";

import SleepingDoggy from "../../images/SleepingDoggy.png";

class ErrorPage extends React.Component {
  render() {
    const { t } = this.props;
    const { children } = this.props;
    const message = this.props.message || t('common.default-error');
    const buttonText = this.props.buttonText || t('common.take-me-home');
    const home = this.props.home || ROUTES.HOME;

    return (
      <>
        <Container className="p-3" fluid>
          <Row noGutters>
            <Col xs={12} md={{ span: 4, offset: 2 }}>
              <div className="p-2 text-right">
                <h1 className="copy-hero">{t('common.sorry')}</h1>

                { children ?
                  <div>{children}</div> :
                  <p>{message}</p>
                }

                <Cta as="link" href={home} className="mt-5 mb-5">
                  {buttonText}
                </Cta>
              </div>
            </Col>
            <Col xs={12} md={{ span: 4 }}>
              <img src={SleepingDoggy} className="mt-5 w-100" />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withTranslation()(ErrorPage);
