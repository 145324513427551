import { get, del, post } from './requests';
import queryString from 'query-string';

export function getProfiles(families) {
  return post('/owner/v1/profiles', { families }).then(res => res.json());
}

export function getActivityFeed(families, limit, offset) {
  const url = queryString.stringifyUrl({
    url: '/owner/v1/activities',
    query: { limit, offset }
  },
  { skipNull: true });

  return post(url, { families }).then(res => res.json());
}

export function addReaction(activityId, reactionType) {
  return post(`/owner/v1/activities/${activityId}/reactions`, { reaction: reactionType })
    .then(res => res.json());
}

export function removeReaction(activityId, reactionId) {
  return del(`/owner/v1/activities/${activityId}/reactions/${reactionId}`);
}

export function searchProfiles(q) {
  const url = queryString.stringifyUrl({
    url: '/owner/v1/search',
    query: { q }
  },
  { skipNull: true });

  return get(url).then(res => res.json());
}

export function saveAchievements(achievements) {
  return post('/owner/v1/achievements', { achievements });
}