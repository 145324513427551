import store from '../redux/store';

// This script attaches to each item in the navigation bar
// and listens for routing changes in the Redux store (triggered by client-side routing).
// It activates the navigation item when client-side routing happens.
export default function navigation(targetPath) {
  return {
    unsubscribe: null,
    isActive: false,
    init() {
      this.isActive = window.location?.pathname?.startsWith(targetPath);

      this.unsubscribe = store.subscribe(() => {
        const state = store.getState();
        const { pathname } = state;
        this.isActive = pathname.startsWith(targetPath);
      });
    },
    destroy() {
      this.unsubscribe?.();
    }
  };
}
