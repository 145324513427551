// Retry Wrapper around React.lazy
// Sometimes, because of code-splitting, users will see errors because the set of chunks
// has changed due to an app update.
// This wrapper will reload the app window automatically, to make their user experience better.

import React from 'react';
const STORAGE_KEY = 'brb-retry-count';

export default function(componentImport) {
  return React.lazy(function() {
    return new Promise((resolve, reject) => {

      // try to import the component
      componentImport().then((component) => {
        window.sessionStorage.setItem(STORAGE_KEY, '0'); // success so reset the refresh
        resolve(component);
      }).catch((error) => {
        // check if the window has already been refreshed
        const retryCount = parseInt(window.sessionStorage.getItem(STORAGE_KEY) || '0');

        if (retryCount < 5) {
          window.sessionStorage.setItem(STORAGE_KEY, String(retryCount + 1));

          console.info(`Retrying lazy import after ${delay} ms`);
          const delay = Math.pow(2, retryCount) * 200;

          // refresh the page with exponential backoff
          return setTimeout(() => {
            window.location.reload();
          }, delay);
        }

        // retried enough times already
        reject(error); 
      });
    });
  });
}