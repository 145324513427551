export const DEPARTURE_EXERCISE = 'DepartureExercise';
export const DIAB_EXERCISE = 'DiabExercise';
export const CRATE_EXERCISE = 'CrateExercise';
export const MAT_EXERCISE = 'MatExercise';

export const EXERCISE_TYPES = [
  DEPARTURE_EXERCISE,
  DIAB_EXERCISE,
  CRATE_EXERCISE,
  MAT_EXERCISE,
];