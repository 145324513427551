import { normalizeExercise } from "../hooks/useScenarios";
import { del, get, post, put } from "./requests";
import queryString from "query-string";

export function getExercises({ descending = false, type = 'DepartureExercise' } = {}) {
  const url = queryString.stringifyUrl({
    url: '/owner/v3/exercises',
    query: {
      descending: descending ? 1 : null,
      type,
    }
  }, { skipNull: true });
  return get(url).then(res => res.json());
}

export function getExercise(id) {
  return get(`/owner/v3/exercises/${id}`).then(res => res.json());
}

export function getBestFitLines(byScenario) {
  const url = queryString.stringifyUrl({
    url: "/owner/v1/graphs/bestfit",
    query: {
      by_scenario: byScenario ? true : null
    }
  }, { skipNull: true });

  return get(url).then(res => res.json());
}

export function createExercise(exercise) {
  return post("/owner/v3/exercises", normalizeExercise(exercise));
}

export function updateExercise(exercise, id) {
  return put(`/owner/v3/exercises/${id}`, normalizeExercise(exercise));
}

export function deleteExercise(id) {
  return del(`/owner/v3/exercises/${id}`);
}

export function getStatistics() {
  return get('/owner/v1/game_stats').then(res => res.json());
}
