import { loadStripe } from '@stripe/stripe-js';

export default function checkout(clientSecret) {
  return {
    elements: null,
    stripe: null,
    hasError: false,
    loading: false,
    async init() {

      // eslint-disable-next-line
      this.stripe = await loadStripe(process.env.STRIPE_PUBLIC_KEY);

      this.elements = this.stripe.elements({ clientSecret });
      const paymentElement = this.elements.create('payment');
      paymentElement.mount('#checkoutElement');

    },
    async completePurchase() {
      this.loading = true;

      const baseURL = window.location.origin;

      const { error } = await this.stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          return_url: `${baseURL}/trainer/licenses`
        }
      });

      this.loading = false;

      if (error) {
        this.hasError = true;
      }
    }
  };
}
