import { BrainCloudWrapper } from 'braincloud';

import curry from 'lodash/curry';
import curryRight from 'lodash/curryRight';
import filter from 'lodash/filter';
import map from 'lodash/map';

const _bc = new BrainCloudWrapper();

// The BrainCloudWrapper functions take a callback function for handling the
// response. I prefer a promise-based approach, and currying is the first step
// to achieving that goal.
const authenticateUniversal = curry(_bc.authenticateUniversal);
const readUserState = curry(_bc.playerState.readUserState);
const incrementUserStats = curry(_bc.playerStatistics.incrementUserStats);
const resetUser = curry(_bc.playerState.resetUser);
const updateUserName = curry(_bc.playerState.updateUserName);
const updateSummaryFriendData = curry(_bc.playerState.updateSummaryFriendData);
const postScoreToLeaderboard = curry(_bc.leaderboard.postScoreToLeaderboard);
const getGlobalLeaderboardPage = curry(_bc.leaderboard.getGlobalLeaderboardPage);
const getGlobalLeaderboardView = curry(_bc.leaderboard.getGlobalLeaderboardView);
const getSocialLeaderboard = curry(_bc.leaderboard.getSocialLeaderboard);
const removePlayerScore = curry(_bc.leaderboard.removePlayerScore);
const findUserByExactUniversalId = curry(_bc.friend.findUserByExactUniversalId);

const addFriends = curry(_bc.friend.addFriends);
const listFriends = curry(_bc.friend.listFriends);
const removeFriends = curry(_bc.friend.removeFriends);
const getSummaryDataForProfileId = curry(_bc.friend.getSummaryDataForProfileId);

const sendEvent = curry(_bc.event.sendEvent);
const getEvents = curry(_bc.event.getEvents);
const deleteIncomingEvents = curry(_bc.event.deleteIncomingEvents);

// for some reason, this function takes a callback as the first parameter, as opposed
// to the last parameter like the other ones
const readAchievements = curryRight(_bc.gamification.readAchievements);

const SESSION_RELATED_ERRORS = [ 40303, 40304, 40306, 40356, 40365, 40426 ];

let game_creds = {};

// fn here is a curried function that has been called with all parameters
// *except* for the callback. We invoke the function and pass in a callback
// which resolves or rejects the promise.
const _call = (fn) => {
  return new Promise((resolve, reject) => {
    fn((result) => {
      if (result.status === 200) {
        resolve(result.data);
      } else {
        const { reason_code } = result;

        // for session-related errors, re-authenticate and try again
        if (SESSION_RELATED_ERRORS.includes(reason_code)) {

          console.log('Retrying BC due to session problem', result);

          bcAuthenticate()
            .then(() => {
              console.log('BC reauthenticated');
              resolve(_call(fn));
            });
        } else {
          reject(result);
        }
      }
    });
  });
};

export const RECENT_ACTIVITY_LEADERBOARD = 'recent_activity';
export const TOTAL_EXERCISES_LEADERBOARD = 'total_exercises';
const EVENT_INVITE_FRIEND = 'evt_invite_friend';

export const setCredentials = (credentials) => {
  game_creds = credentials;
};

export const bcAuthenticate = () => {
  const { id, key, game_id, game_password } = game_creds;

  _bc.initialize(id, key, '1.0');

  return _call(authenticateUniversal(game_id, game_password, true));
};

export const getUserState = () => {
  return _call(readUserState);
};

export const incrementStats = async (statistics) => {
  // we're not doing anything with XP for now
  const xp = 0;
  return _call(incrementUserStats(statistics, xp));
};

export const getGameAchievements = async () => {
  const includeMetadata = true;
  return _call(readAchievements(includeMetadata));
};

export const setUserName = async (name) => {
  return _call(updateUserName(name));
};

export const setFriendData = async (data) => {
  return _call(updateSummaryFriendData(data));
};

export const postToLeaderboard = async (leaderboardId, score) => {
  const otherData = null;
  return _call(postScoreToLeaderboard(leaderboardId, score, otherData));
};

export const getLeaderboardTop = async (leaderboardId, count) => {
  return _call(getGlobalLeaderboardPage(leaderboardId, 'HIGH_TO_LOW', 0, count - 1));
};

export const getLeaderboardNearby = async (leaderboardId) => {
  return _call(getGlobalLeaderboardView(leaderboardId, 'HIGH_TO_LOW', 3, 3));
};

export const getFriendLeaderboard = async (leaderboardId) => {
  const replaceName = false;
  return _call(getSocialLeaderboard(leaderboardId, replaceName));
};

export const makeFriends = async (ids) => {
  return _call(addFriends(ids));
};

export const getFriends = async () => {
  const platform = 'brainCloud';
  const includeSummaryData = true;
  return _call(listFriends(platform, includeSummaryData));
};

export const unfriend = async (ids) => {
  return _call(removeFriends(ids));
};

// inviting friends usings the Event API
export const inviteFriend = async (id) => {
  const eventType = EVENT_INVITE_FRIEND;
  return _call(sendEvent(id, eventType, null));
};

export const getInvites = async () => {
  const { incoming_events } = await _call(getEvents);
  const events = filter(incoming_events, { eventType: EVENT_INVITE_FRIEND });

  return Promise.resolve({
    incoming_events: events
  });
};

export const deleteEvents = async (evIds) => {
  return _call(deleteIncomingEvents(evIds));
};

export const getSummaryProfile = async (id) => {
  return _call(getSummaryDataForProfileId(id));
};

// We will call this if a user decides to opt out of social features.
// This will:
// - unfriend all friends
// - remove scores from leaderboard
// Point being, we don't want other people to see us anymore
export const socialOptOut = async () => {
  const friends = await getFriends();
  const ids = map(friends.friends, 'profileId');
  return Promise.all([
    unfriend(ids),
    _call(removePlayerScore(TOTAL_EXERCISES_LEADERBOARD, -1)),
    _call(removePlayerScore(RECENT_ACTIVITY_LEADERBOARD, -1)),
  ]);
};

export const findUser = async (universalId) => {
  return _call(findUserByExactUniversalId(universalId));
};

export const selfReset = async () => {
  return _call(resetUser);
};

// This is called often by Cypress tests, as a setup function to reset
// the user profile to its original state.
// This will:
// - remove player statistics and achievements
// - do the same stuff as opting out of social features
const reset = async () => {
  return Promise.all([
    _call(resetUser),
    socialOptOut(),
  ]);
};

const clearProfile = async () => {
  _bc.setStoredAnonymousId('');
  _bc.setStoredProfileId('');
  _bc.setStoredSessionId('');
};

const module = {
  setCredentials,
  bcAuthenticate,
  getUserState,
  incrementStats,
  getGameAchievements,
  setUserName,
  setFriendData,
  postToLeaderboard,
  getLeaderboardTop,
  getLeaderboardNearby,
  getFriendLeaderboard,
  makeFriends,
  getFriends,
  unfriend,
  inviteFriend,
  getInvites,
  getSummaryProfile,
  deleteEvents,
  socialOptOut,
  findUser,

  // only to be used for Cypress testing
  reset,
  clearProfile,
};

// expose the API functions to the Cypress window object
if (window.Cypress) {
  window.__braincloudApi__ = module;
}
